
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Kefu from "@/components/home/Kefu.vue";
import Solution from "@/components/home/Solution.vue";
import Case from "@/components/home/Case.vue";
import Press from "@/components/home/Press.vue";
import Activity from "@/components/home/Activity.vue";
import Footer from "@/components/home/Footer.vue";
// import Nav from "@/components/home/Nav.vue";



export default defineComponent({
  components: {
    // Kefu,
    Solution,
    Case,
    Activity,
    Footer,
    Press,
    // Nav
  },
  props: ["current_nav"],
  setup(props, context) {
    const productMenu = [
      // {
      //   img: "../../../public/img/menu_icon/icon3.png",
      //   name: "数字零售",
      // },
      {
        img: "../../../public/img/menu_icon/icon2.png",
        name: "数字婚嫁",
      },
      // {
      //   img: "../../../public/img/menu_icon/icon4.png",
      //   name: "数字电商",
      // },
      {
        img: "../../../public/img/menu_icon/icon1.png",
        name: "数字财税",
      },
      // {
      //   img: "../../../public/img/menu_icon/icon2.png",
      //   name: "数字办公",
      // },
      {
        img: "../../../public/img/menu_icon/icon5.png",
        name: "云产品",
      },
    ];
    const productList = [
      // {
      // 数字零售
      // list: [
      // {
      //   key: "restaurant",
      //   name: "餐饮外卖",
      //   img: "../../../public/img/product/menu/digital_retail/restaurant.png",
      //   _img: "../../../public/img/product/menu/digital_retail/_restaurant.png",
      // },
      // {
      //   key: "fresh",
      //   name: "生鲜外卖",
      //   img: "../../../public/img/product/menu/digital_retail/_fresh.png",
      //   _img: "../../../public/img/product/menu/digital_retail/_fresh.png",
      // },
      // {
      //   key: "seafood",
      //   name: "海鲜外卖",
      //   img: "../../../public/img/product/menu/digital_retail/_seafood.png",
      //   _img: "../../../public/img/product/menu/digital_retail/_seafood.png",
      // },
      // {
      //   key: "helping_farmers",
      //   name: "农业助农",
      //   img: "../../../public/img/product/menu/digital_retail/_helping_farmers.png",
      //   _img: "../../../public/img/product/menu/digital_retail/_helping_farmers.png",
      // },
      // ],
      // },
      {
        // 数字婚嫁
        list: [
          {
            key: "hjhy",
            name: "婚嫁互娱",
            img: "../../../public/img/product/menu/digital_marriage/hjhy.png",
            _img: "../../../public/img/product/menu/digital_marriage/_hjhy.png",
          },
          // {
          //   key: "wedding_hall",
          //   name: "婚礼堂管理",
          //   img: "../../../public/img/product/menu/digital_marriage/_wedding_hall.png",
          //   _img: "../../../public/img/product/menu/digital_marriage/_wedding_hall.png",
          // },
          // {
          //   key: "invitation_card",
          //   name: "请柬",
          //   img: "../../../public/img/product/menu/digital_marriage/_invitation_card.png",
          //   _img: "../../../public/img/product/menu/digital_marriage/_invitation_card.png",
          // },
          // {
          //   key: "invitation",
          //   name: "邀请函",
          //   img: "../../../public/img/product/menu/digital_marriage/_invitation.png",
          //   _img: "../../../public/img/product/menu/digital_marriage/_invitation.png",
          // },
        ],
      },
      // {
      //   // 数字电商
      //   list: [
      //     {
      //       key: "marriage_ds",
      //       name: "婚嫁电商",
      //       img: "../../../public/img/product/menu/digital_ds/_marriage_ds.png",
      //       _img: "../../../public/img/product/menu/digital_ds/_marriage_ds.png",
      //     },
      //     {
      //       key: "camp",
      //       name: "营地电商",
      //       img: "../../../public/img/product/menu/digital_ds/_camp.png",
      //       _img: "../../../public/img/product/menu/digital_ds/_camp.png",
      //     },
      //   ],
      // },
      {
        // 数字财税
        list: [
          {
            key: "small_business",
            name: "小型企业SaaS管理云",
            img: "../../../public/img/product/menu/digital_cs/small_business.png",
            _img: "../../../public/img/product/menu/digital_cs/_small_business.png",
          },
          {
            key: "startups",
            name: "初创企业SaaS管理云",
            img: "../../../public/img/product/menu/digital_cs/startups.png",
            _img: "../../../public/img/product/menu/digital_cs/_startups.png",
          },
          {
            key: "small_micro",
            name: "小微企业管理上云",
            img: "../../../public/img/product/menu/digital_cs/small_micro.png",
            _img: "../../../public/img/product/menu/digital_cs/_small_micro.png",
          },
        ],
      },
      // {
      //   // 数字办公
      //   list: [
      //     {
      //       key: "office",
      //       name: "办公协同",
      //       img: "../../../public/img/product/menu/digital_office/_office.png",
      //       _img: "../../../public/img/product/menu/digital_office/_office.png",
      //     },
      //   ],
      // },
      {
        // 云产品
        list: [
          {
            key: "cloud",
            name: "云产品",
            img: "../../../public/img/product/menu/cloud_product/cloud.png",
            _img: "../../../public/img/product/menu/cloud_product/_cloud.png",
          },
        ],
      },
    ];
    const solutionMenu = [
      {
        img: "../../../public/img/menu_icon/icon1.png",
        name: "消费金融解决方案",
      },
      {
        img: "../../../public/img/menu_icon/icon2.png",
        name: "婚嫁解决方案",
      },
      {
        img: "../../../public/img/menu_icon/icon4.png",
        name: "零售解决方案",
      },
    ];
    const solutionList = [
      {
        // 消费金融解决方案
        list: [
          {
            key: "consumer_finance",
            title: "消费金融",
            desc: "消费分期",
            content: "消费场景解决方案",
            bg: "../../../public/img/solution/menu/consumer_finance/_consumer_finance.png",
            _bg: "../../../public/img/solution/menu/consumer_finance/consumer_finance.png",
          },
        ],
      },
      {
        // 婚嫁解决方案
        list: [
          {
            key: "wedding_hall",
            title: "婚礼堂",
            desc: "一站式婚礼堂",
            content: "婚礼堂场景解决方案",
            bg: "../../../public/img/solution/menu/marriage/_wedding_hall.png",
            _bg: "../../../public/img/solution/menu/marriage/wedding_hall.png",
          },
          {
            key: "hjhy",
            title: "婚嫁互娱",
            desc: "婚礼现场IOT",
            content: "婚礼现场互动场景解决方案",
            bg: "../../../public/img/solution/menu/marriage/_hjhy.png",
            _bg: "../../../public/img/solution/menu/marriage/hjhy.png",
          },
        ],
      },
      {
        // 零售解决方案
        list: [
          {
            key: "restaurant",
            title: "餐饮零售",
            desc: "餐饮外卖",
            content: "多生态餐饮场景解决方案",
            bg: "../../../public/img/solution/menu/retail/_restaurant.png",
            _bg: "../../../public/img/solution/menu/retail/restaurant.png",
          },
          // {
          //   key: "fresh",
          //   title: "生鲜冷链",
          //   desc: "生鲜订单",
          //   content: "生鲜冷链场景解决方案",
          //   bg: "../../../public/img/solution/menu/retail/_fresh.png",
          //   _bg: "../../../public/img/solution/menu/retail/_fresh.png",
          // },
          // {
          //   key: "helping_farmers",
          //   title: "助农惠农",
          //   desc: "农业配送",
          //   content: "扶贫助农场景解决方案",
          //   bg: "../../../public/img/solution/menu/retail/_helping_farmers.png",
          //   _bg: "../../../public/img/solution/menu/retail/_helping_farmers.png",
          // },
          // {
          //   key: "online_ds",
          //   title: "线上电商",
          //   desc: "互联网电商",
          //   content: "互联网电商场景解决方案",
          //   bg: "../../../public/img/solution/menu/retail/_online_ds.png",
          //   _bg: "../../../public/img/solution/menu/retail/_online_ds.png",
          // },
        ],
      },
    ];
    const navIndex = ref(0);
    const router = useRouter();
    const pIndex = ref(0);
    const sIndex = ref(0);
    const chosenProduct = ref("");
    const chosenSolution = ref("");

    onMounted(() => {
      const _index = parseInt(sessionStorage.getItem("navIndex")!);
      navIndex.value = _index ? _index : 0;
    });

    watch(props, (old, newProps) => {
      navIndex.value = newProps.current_nav;
    });

    const chooseMenu = (index: number, type: string) => {
      type === "p" ? (pIndex.value = index) : (sIndex.value = index);
    };
    const productDetails = (topic: string, index: number, _index: number) => {
      let routeData = null;
      if (index === 0 && _index === 0) {
        routeData = router.resolve({ path: `/product/digital_marriage` });
      } else if (index === 1) {
        routeData = router.resolve({
          path: `/product/finance_tax`,
          query: { id: _index + 1 },
        });
      } else if (index === 2) {
        routeData = router.resolve({ path: `/product/cloud_product` });
      }
      window.open(routeData?.href, "_blank");
    };
    const solutionDetails = (topic: string, index: number, _index: number) => {
      let routeData = null;
      if (index === 0) {
        routeData = router.resolve({ path: `/solution/consumer_finance` });
      } else if (index === 1) {
        routeData = router.resolve({
          path: `/solution/marriage`,
          query: { id: _index + 1 },
        });
      } else if (index === 2 && _index === 0) {
        routeData = router.resolve({ path: `/solution/retail` });
      }
      window.open(routeData?.href, "_blank");
    };

    return {
      productMenu,
      productList,
      solutionMenu,
      solutionList,
      props,
      navIndex,
      pIndex,
      sIndex,
      chosenProduct,
      chosenSolution,
      chooseMenu,
      productDetails,
      solutionDetails,
    };
  },
});
