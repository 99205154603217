
import { ref, onMounted, nextTick } from "vue";
import { onUnmounted } from "@vue/runtime-core";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    // 定义矩形元素的初始状态
    const items = ref([
      {
        width: "50%",
        marginLeft: "0px",
        clicked: true,
        title: "婚嫁",
        entitle: "MARRIAGE",
        description: "助力传统婚嫁行业\n品牌服务升级与数字化转型",
      },
      {
        width: "calc(50% / 2 - 5px)",
        marginLeft: "5px",
        clicked: false,
        title: "教育",
        entitle: "EDUCATION",
        description: "落实教育创新改革\n科学提质减负",
      },
      {
        width: "calc(50% / 2 - 5px)",
        marginLeft: "5px",
        clicked: false,
        title: "健康",
        entitle: "HRALTH",
        description: "医学大数据赋能健康\n助力产业模式升级",
      },
    ]);

    return { items };
  },
};
