<template>
  <div class="home-title">
    <div class="title" style="display: flex; align-items: center;color: #000;margin: 7px 0;">
      <img src="../../../public/img/home/solution/en_home_goldcircle.png" alt="">
      <div style="display: flex;flex-direction: column;">
        <p style="color: #fff;font-size: 20px;margin-left: -15px;margin-top: 10px; letter-spacing: 2px;">WEBCAST & EVENTS</p>
        <p class="desc" style="color: #AAABAC; font-size: 15px;display: flex;align-items: center;margin: 5px auto;font-weight: 200;">MORE <img src="../../../public/img/home/case/right.png" style="margin-left: 10px;cursor:pointer" alt=""></p>
      </div>
    </div>
  </div>
  <div class="case-content">
    <!-- controls -->
    <video class="video-content" loop autoplay muted src="https://static.91fd.com/video/分蛋宣传片_english.mp4"></video>
  </div>

</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.home-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.case-content{
  width: 100%;
  height: 100%;
  .video-content{
    width: 100%;
    height: 70%;
    object-fit: cover;
  }
}
</style>