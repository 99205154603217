
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const leftList = [
      {
        img: "/img/home/floor3_1.png",
        title: "商户推荐奖励计划",
        time: "2022/06/20 18:35",
      },
      {
        img: "/img/home/floor3_2.png",
        title: "数创新境，因人而熠",
        time: "2022/06/20 18:35",
      },
    ];
    const rightList = [
      {
        time: "2022/06/20 18:35",
        content: "创无限，焕新生｜分蛋科技受邀出席婚嫁行业产业联盟会",
      },
      {
        time: "2022/05/21 17:35",
        content: "520｜今天，占据平台动态的C位就是TA！",
      },
      {
        time: "2022/05/11 12:35",
        content: "分蛋科技荣膺“高新技术企业认证”助推中小企业数字化转型",
      },
      {
        time: "2022/04/11 12:35",
        content: "分蛋科技公布2021年度业绩，金蛋壳持续领先市场份额",
      },
    ];

    // const infoClick=()=>{
    //   console.log(22222)
    // }

    return {
      leftList,
      rightList,
      // infoClick
    };
  },
});
